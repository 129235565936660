@import "src/scss/variables";
@import "src/scss/flex";
@import "src/scss/mixins";
@import "src/scss/media_queries";
@import "src/scss/theme";

.title {
  font-size: $headingSectionThree;
  color: $footerBlack;
  font-family: opensans-semibold;
  padding: 10px;
  padding-left: 25px;
  padding-bottom: 20px;
}
.content {
  padding: 0px 25px;
}

.footer {
  @include flexbox();
  @include justify-content(center);
  padding: 80px 0px 30px 0px;
}

.divider {
  border-top: 1px solid $lightGrey;
  margin-top: 25px;
  margin-bottom: 13px;
}
.plus-btn {
  margin-bottom: -6px;
  margin-right: 10px;
}

.add-btn {
  min-width: 204px;
}

.empty-events-box {
  min-height: 207px;
  background-color: $greyWhite;
  @include border-radius(8px);
  text-align: center;
  color: rgba($pureBlack, 0.5);
  font-family: opensans-regular;
  font-size: $headingSectionThree;
  @include flexbox();
  @include justify-content(center);
  @include align-items(center);
}

.event-add-btn {
  margin-top: 32px;
  margin-left: 12px;
}

.event-list {
  background-color: rgba($lightGrey, 0.2);
  padding: 3px 10px;
  margin-bottom: 10px;
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  @include border-radius(8px);
}

.events-input-box {
  width: 100%;
}

.events {
  width: 100%;
  @include flexbox();
  @include justify-content(space-between);
}

.cancel-btn {
  margin-right: 10px;
  button {
    min-width: 164px;
    @include flexbox();
    @include justify-content(center);
  }
}

.buttons-container {
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .button {
    width: 75%;
    margin-top: 12px;
  }
}

.plus-btn {
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.25));
  width: 41px;
  height: 41px;
  background-color: #FFF;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.error-message {
  color: red;
  font-size: 14px;
  width: 100%;
}

.box-field { 
    margin-top: 20px;
    margin-bottom: 20px;
 }

.save-button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
@import 'src/scss/theme';
@import 'src/scss/functions';

@mixin commonStyles(){
  color: $footerBlack;
  &.semiBold{
  }
  &.block{
    display: block;
  }
  &.center{
    text-align: center;
  }
}

.main{
  @include commonStyles();
  font-size: $textParagraphOne;
  font-family: opensans-regular;
}

.head{
  @include commonStyles();
  font-family: opensans-regular;
  font-size: $headingSectionThree;
}

.link{
  @include commonStyles();
  font-size: toRem(14);
  text-decoration: underline;
  cursor: pointer;
}

.error{
  @include commonStyles();
  color: $errorRed;
  font-size: 0.8rem;
}
.account-icon {
    display: flex;
    justify-content: center;
}
.container {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.done-btn {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}
.error-message {
    color: red,
}
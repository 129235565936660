@import 'src/scss/variables';

.app-input {
  display: flex;
  flex-direction: column;

  label {
    flex: 1;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  input {
    flex: 1;
    padding: 0.5rem 0.75rem;
    outline: none;
    border-radius: 0.25rem;
    border: 1px solid #ccc;
    outline: none;
    font-size: 1rem;
    color: #333;
    width: 100%;
    background-color: transparent;

    &:disabled {
      background-color: $disabled-light;
    }

    &:focus {
      border: 1px solid $primary;
      box-shadow: 0 0 2px $primary;
    }
  }
  .description {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    color: $disabled;
  }
}

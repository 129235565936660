@import "src/scss/variables";
@import "src/scss/flex";
@import "src/scss/mixins";
@import "src/scss/media_queries";
@import "src/scss/theme";

.label {
  font-family: Avenir-Regular !important;
  font-size: 14px !important;
  font-weight: unset !important;
}

.select {
  border: 1px solid #e5e5e5 !important;
  border-radius: 0.45rem !important;
  font-size: 14px !important;
  padding: 0.25rem 0.5rem !important;
  color: $blackTwo !important;
  margin-top: -6px !important;
}

.table-data {
  height: calc(100vh - 170px);
  overflow: auto;
}

.api-key-box {
  @include flexbox();
  @include align-items(center);
  font-size: $textParagraphOne;
  padding: 6px 10px 14px 22px;
  .api-key-title {
    margin-top: 7px;
    font-size: $headingSectionThree;
    margin-right: 30px;
  }
  .api-key-desc {
    margin-top: 7px;
  }
}

.divider {
  border-top: 1px solid $lightGrey;
}
.header {
  @include flexbox();
  @include justify-content(space-between);
  padding: 10px 20px 10px 20px;
  color: $pureBlack;
  .header-left {
    font-family: opensans-semibold;
    font-size: $headingSectionThree;
  }
  .header-right {
    font-family: opensans-regular;
    @include flexbox();
    @include align-items(center);
    .merchant-img {
      width: 32px;
      height: 32px;
      margin-left: 20px;
      background-color: $greyWhite;
      @include border-radius(50%);
    }
  }
}

.footer {
  padding: 20px 0px;
  @include flexbox();
  @include justify-content(center);
}
.plus-btn {
  margin-bottom: -6px;
  margin-right: 20px;
}

@import "src/scss/variables";
@import "src/scss/media_queries";
@import "src/scss/theme";
@import "src/scss/flex";

.titleContainer {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $greyWhite;

  .title {
    font-weight: 600;
    display: inline-block;
    font-size: $baseFontSize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 20px;
    padding-right: 20px;
    color: $pureWhite;
    text-transform: uppercase;
  }

  .menuCollapseButton {
    position: absolute;
    left: 0px;
    background-color: #fff;
    border: 0;
    display: flex;
    height: 100%;
    width: 2rem;
    align-items: center;
    justify-content: center;
  }
}

.menu {
  list-style-type: none;
  padding: 0px;

  .listItem {
    padding: 0.5rem 0;
    

    .linkContainer {
      .linkItem {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: $headingSectionThree;
        color: $dark;
        font-weight: 600;

        &.active {
          color: $primary;
        }
      }
    }
  }

  .childMenu {
    list-style-type: square;
    padding-left: 0.5rem;

    .childItemStyle {
      font-size: 1rem;
    }
  }
}

.logo {
  height: fit-content;
}

.footerContainer {
  display: flex;
  // align-items: center;
  margin-top: auto;
  justify-content: center;

  .logo {
    height: 35px;
  }
}

.circleBox {
  background: $pureWhite;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 0;
}

.menu-box {
  position: relative;
  width: 100%;
  
}

.menu-box-inner {
  
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.menu-box-inner-inactive {
  opacity: 0.05;
  transition: opacity 0.1s ease-in-out;
}

.menu-box-outer {
  width: 0px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;

  a {
    white-space: pre;
    padding-left: 0px !important;
    padding-right: 0px !important;
    transition: padding 0.7s;
  }

  transition: width 0.3s ease-in,
  overflow 0.5s ease-in-out;
}

.menu-box-outer-active {
  width: 100%;
  overflow: unset;

  a {
    white-space: pre;
    padding-left: 50px !important;
    padding-right: 30px !important;
    transition: padding 0.7s;
  }

  transition: width 0.5s,
  overflow 0.5s;
}

.sandbox {
  @include flexbox();
  @include align-items(center);
  @include justify-content(flex-start);
  padding: 20px 10px 20px 30px;
  border-top: 1px solid $pureWhite;
}

.sandbox-off {
  color: $pureWhite;
  font-size: $headingSectionThree;
  @include flexbox();
  padding-top: 10px;
  @include align-items(center);
}

@include screen-below("xl") {
  .userMenu {
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 6rem;
      overflow: hidden;
    }
  }

  .titleContainer {
    .title {
      font-size: 1rem;
    }
  }
}

.savvy-icon {
  cursor: pointer;
  padding: 15px 0px 15px 0px;
}

@include screen-up("lg") {
  .titleContainer {
    .menuCollapseButton {
      display: none;
    }
  }
}

@include screen-below("lg") {
  .userMenu {
    span {
      max-width: 10rem;
    }
  }
}

$primary: #eda12c;
$primary-dark: #e0b003;
$dark-xt: #212529;
$dark: #484848;
$black: #000000;
$dark-secondary: #51524a;
$dark-light: rgba(0, 0, 0, 0.6);
$medium: #444;
$disabled: #999;
$disabled-medium: #e5e5e5;
$disabled-light: #f3f3f3;
$light: #eeeeee;
$extra-light: #ffffff;
$success-light: #89caa7;
$gray-text: #545353;
$gray-button: #acacac;
$gray-medium: #f3f3f3;
$gray-light: #b1b1b1;
$input-border: #c4c4c4;
$border-medium: #d9d9d9;
$peach: #f8d9ab;
$red-dark: #c74b4b;
$red: #ff0000;
$green: #1eb605;
$brown-dark: #47300D;
$yellow-0: rgb(158, 158, 6); 

//according to figma theme
$gray-0: #000000;
$gray-1: #1a1a1a;
$gray-2: #4D4D4D;

/*=============================================
=            Font Size            =
=============================================*/

$h1: 26px;
$h2: 22px;
$h3: 20px;
$h4: 18px;
$h5: 16px;
$h6: 14px;

/* Headings */

$pageHeading: 1rem;
$cardHeading: 0.9rem;
$inputLabel: 0.9rem;

/*=====  End of Font Size  ======*/

@import "src/scss/variables";
@import "src/scss/theme";
@import "src/scss/flex";

.listItemContainer {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $pureWhite;
  font-family: opensans-regular;
  font-size: $headingSectionThree;

  padding: 10px 30px 10px 40px;
  &:hover {
    color: rgba($pureWhite, 0.7);
  }
  .icon {
    margin-left: auto;
  }
  &.active {
    color: $pureWhite;
    font-family: opensans-bold;
  }
}

.linkItem {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $pureWhite;
  font-family: opensans-regular;
  font-size: $headingSectionThree;

  width: 100%;
  @include flexbox();
  @include justify-content(space-between);
  text-decoration: none;
  padding: 10px 30px 10px 40px;

  &:hover {
    color: rgba($pureWhite, 0.7);
  }

  &.active {
    color: $pureWhite;
    font-family: opensans-bold;
  }
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  text-align: center;
  height: 20px;
}
.icon{
  margin-right: 6px;
}
.first-list-anchor {
  padding: 35px 30px 10px 40px !important;
}

.link-border {
  border-bottom: 1px solid rgba(243, 243, 243, 0.6);
}

.childMenu {
  list-style-type: none;
  padding-left: 0rem;
  .linkItem {
    font-family: opensans-regular;

    // font-size: $headingSectionThree;
    padding: 5px 30px 5px 60px;
    &.active {
      color: $pureWhite;
      font-family: opensans-bold;
    }
  }
}

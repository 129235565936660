@import "src/scss/theme";
@import "src/scss/functions";

.input {
  width: 100%;
  font-size: $textParagraphThree;
  padding: map-get($textInput, "padding");
  color: $greyBlack;
  border-radius: map-get($textInput, "borderRadius");
  border: unset;
  &::placeholder {
    color: $footerBlack;
    opacity: 0.5;
    font-size: $textParagraphThree;
  }
  &:focus {
    outline: none;
  }
  &.inline {
    padding: 0 toRem(8);
    width: toRem(60);
  }
}
.medium {
  font-size: $headingSectionThree;
  &::placeholder {
    font-size: $headingSectionThree;
  }
}

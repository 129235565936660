@import 'src/scss/theme';

.modal{
  height: map-get($modal, "height");
  width: map-get($modal, "width");
  border-radius: map-get($modal, "borderRadius");
  background-color: $pureWhite;
  display: flex;
  align-items: center;
  justify-content: center;
}
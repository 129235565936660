@import 'src/scss/theme';

.main-container {
  .main-container__control {
    height: map-get($textInput, 'height');
    font-size: map-get($textInput, 'fontSize');
    padding: map-get($textInput, 'padding');
    color: $themeBlack;
    border-radius: map-get($textInput, 'borderRadius');
    border: map-get($textInput, 'borderWidth') solid $themeBlack;
    &:hover {
      border-color: $themeBlack;
    }
  }
  .main-container__indicator-separator {
    background-color: $themeBlack;
  }
  .main-container__indicator {
    color: $themeBlack;
  }
}

.async-select {
  label {
    font-size: $headingSection;
    color: $themeBlack;
  }
}

@import 'src/scss/media_queries';
@import 'src/scss/variables';
@import 'src/scss/theme';

.form-check-input {
  &:checked {
    background-color: $themeGreen !important;
    border-color: $themeGreen !important;
    // border-color: $themeGreen !important;
    box-shadow: 0 0 0 0.25rem rgba($themeGreen, 0.25) !important;
  }
}

@import 'src/scss/media_queries';
@import 'src/scss/variables';
@import 'src/scss/theme';

.layout-container {
  display: flex;

  .aside {
    position: fixed;
    left: -20%;
    width: 286px;
    height: 100%;
    background-color: $blackTwo;
    transition: all 0.25s ease-in;
    border-right: 1px solid $borderBlack;
    display: flex;
    flex-direction: column;

    &.toggle {
      left: 0;
    }
  }
  .main {
    width: calc(100% - 286px);
    margin-left: 286px;
    transition: all 0.25s ease-in;
    background-color: $pureWhite;

    &.toggle {
      width: 100%;
      margin-left: 0;
    }
  }
}

@include screen-below('lg') {
  .layout-container {
    .aside {
      box-shadow: 0 0 10px rgb(167, 167, 167);
      width: 17rem;
      left: 0;
      z-index: 3;
      &.toggle {
        left: -17rem;
      }
    }
    .main {
      width: 100%;
      margin-left: 0;
      transition: all 0.25s ease-in;
    }
  }
}
@import 'src/scss/variables';
@import 'src/scss/theme';

.inputLabelContainer {
  margin-bottom: 0.2rem;
  .inputLabel {
    font-family: Avenir-DemiBold;
    font-size: $inputLabel;
  }
}
.error {
  font-size: 0.8rem;
  color: $errorRed;
  margin-top: 0.1rem;
}

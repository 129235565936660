@import 'src/scss/variables';
@import 'src/scss/flex';
@import 'src/scss/mixins';
@import 'src/scss/media_queries';
@import 'src/scss/theme';

.sign-in-container {
  display: flex;
  height: calc(100vh);

  .sign-in-form {
    box-shadow: 0px 2px 5px #999;
    padding: 2rem;
    border-radius: 1rem;
    width: 400px;
    margin: auto;
    position: relative;

    .title {
      color: rgba(0, 0, 0, 0.7);
      text-align: center;
      font-weight: 600;
      font-size: 1.2rem;
    }

    .description {
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
      display: block;
    }

    .loginButton {
      margin-top: 20px;
      background-color: '#4caf50';
      color: '#fff';
      &:hover {
        background-color: '#45a049';
      }
    }

    .ssoButton {
      margin-top: 10px;
      background-color: '#4caf50';
      color: '#fff';
      &:hover {
        background-color: '#45a049';
      }
    }

    .text-field {
      margin: 10px 0;
    }
  }

  .password-box {
    position: relative;
    .password-icon {
      position: absolute;
      right: 10px;
      top: 30px;
      cursor: pointer;
    }
  }

  @include screen-below('sm') {
    .sign-in-form {
      max-width: 100%;
    }
  }
}

.admin-merchant-container {
  @include flexbox();
  @include align-items(center);
  .admin-merchant-checkbox {
    margin-right: 5px;
  }
  font-size: $textParagraphThree;
  a {
    color: $themeBlack;
  }
  margin-bottom: 1rem;
}

.disabled-btn {
  opacity: 0.5;
  cursor: not-allowed;
}

.MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle  {
    color: Black;
    font-weight: bold;
}
.createdStyle{
    margin-left: 20px;
}
.statusStyle {
    margin-left: 20px;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-weight: bold;
}

@import "src/scss/variables";
@import "src/scss/theme";

.inputLabelContainer {
  margin-bottom: 0.2rem;
  .inputLabel {
    color: $footerBlack;
  }
}
.error {
  font-size: 0.8rem;
  color: #c74b4b;
  margin-top: 0.1rem;
}

.input-select-one {
  margin-bottom: 14px;
}
.select {
  width: 100%;
  height: map-get($textInput, "height");
  font-size: map-get($textInput, "fontSize");
  padding: map-get($textInput, "padding");
  color: $greyBlack;
  background-color: $greyWhite;
  border-radius: map-get($textInput, "borderRadius");
  border: unset;
  &::placeholder {
    color: $promptGrey;
  }
  &:focus {
    outline: none;
  }
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.2rem center;
  background-size: 1em;
}

.medium {
  height: 44px;
  font-size: $headingSectionThree;
  &::placeholder {
    font-size: $headingSectionThree;
  }
}

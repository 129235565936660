@import "src/scss/variables";

.container {
  display: flex;
  align-items: center;
  margin-top: 0.7rem;
  .input {
    font-size: 2rem;
    input {
      height: 29px;
      width: 69px;
      cursor: pointer;
      margin-top: 0;
    }
  }
}
